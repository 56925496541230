<template>
  <div class="main-page-content">
    <el-button @click="handleClick()"
               key="artist_divide_item"
               size="big"
               type="danger"
               style="margin: 6px"
               v-if="userPermissions.indexOf('oa_manage_import') !== -1">
      撤回
    </el-button>
    <br>
    <br>
    <div class="default-table">
      <el-table
          :border="true"
          :data="dataList"
          :header-cell-style="headClass"
          ref="multipleTable"
          row-key="id"
          style="width: 100%"
          tooltip-effect="dark"
      >
        <el-table-column
            align="center"
            label="序号"
            type="index"
            width="50"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="审批编号"
            prop="code"
            width="150"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="审批名"
            min-width="150"
            prop="title"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="类型"
            width="120"
        >
          <template slot-scope="{row}">
            {{ statusData(row.type) }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="发起人"
            prop="originator.nickname"
            show-overflow-tooltip
            width="80"
        >
          <template slot-scope="{row}">
            {{ row?.originator?.nickname || '??' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="归属红人"
            prop="nickname"
            width="120"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="所属部门"
            min-width="150"
            prop="dpt_name"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="审批总金额"
            width="150"
        >
          <template slot-scope="{row}">
            {{ moneyData(row.content.info) }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="创建时间/修改时间"
            width="150"
            how-overflow-tooltip
        >
          <template slot-scope="{row}">
            {{ row.created_at }} / {{ row.updated_at }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="状态"
            prop="status"
            width="80"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-row justify="right" type="flex">
      <el-col :span="24">
        <Pagination
            :limit.sync="pagingData.page_size"
            :page.sync="pagingData.current_page"
            :total="pagingData.total"
            @pagination="initList"/>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'oaTable',
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      options: { status: {}, source_types: {} },
      orderSort: { id: 'desc' }//默认排序规则
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    //批量样式居中
    headClass() {
      return 'text-align:center'
    },
    async initList() {
      this.loadingStatus = true
      // let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = {}
      Object.assign(searchCondition, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { import_id: this.$route.params.id })
      let data = await this.$api.getOaManageList(searchCondition)
      data.list.forEach((item) => {
        item.type = item.type === 'kol_resource' ? '红人资源库导入' : item.type
        item.status = item.status === 1 ? '未开始' : item.status === 2 ? '进行中' : '已完成'
      })
      this.dataList = data.list
      this.pagingData = data.page_info
      this.loadingStatus = false
    }
    ,
    statusData(value) {
      let typeName = ''
      switch (value) {
        case 1:
          typeName = '推广投放申请'
          break
        case 2:
          typeName = '红人费用申请'
          break
        case 3:
          typeName = '通用付款申请'
          break
        case 4:
          typeName = '通用报销申请'
          break
        case 5:
          typeName = '备用金申请'
          break
        case 6:
          typeName = '差旅申请'
          break
      }
      return typeName
    },
    moneyData(data) {
      let allMoney = ''
      if (data && data.length != 0) {
        data.forEach(({ name, value }) => {
          switch (name) {
            case '付款金额（元）':
              allMoney = value
              break
            case '总报销金额（元）':
              allMoney = value
              break
          }
        })
      }

      return this.moneyFormat(allMoney) + '  元'
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    async handleClick() {
      // /saveOaManage
      this.$confirm('是否确定撤回该审批', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          import_id: this.$route.params.id
        }
        let id = await this.$api.OaDeleteImport(data)
        if (id) {
          this.$notify.success('成功')
          setTimeout(() => {
            window.close()
          }, 800)

        } else this.$notify.error('操作失败')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  },
  mounted() {
    this.initList()
  }
}
</script>

<style scoped>

</style>
